<template>
  <div class="news-list-box">
    <div class="news-list-content">
      <el-carousel
        v-if="swiperList.length"
        class="news-banner-box"
        height="auto"
        autoplay
        loop
        arrow="never"
      >
        <!-- 图片用于占位： 实现banner高度自适应 -->
        <img v-if="swiperList.length" class="mask-banner-img" :src="swiperList[0].picUrl" height="100%" alt="banner">
        <el-carousel-item v-for="(item,index) in swiperList" :key="index">
          <a :href="item.link" target="_blank">
            <img class="swiper-img" :src="item.picUrl" height="100%" alt="banner">
          </a>
        </el-carousel-item>
      </el-carousel>
      <div class="news-content-box">
        <div class="news-list">
          <router-link class="item" v-for="(item,index) in newsList" :key="index" :to="{path:'/home/news/info',query:{newsId:item.id}}">
            <h3 class="title">{{item.title}}</h3>
            <dl class="dl">
              <dt class="dt">
                <img :src="item.imageUrl" width="100%" height="100%" alt="">
              </dt>
              <dd class="dd">
                <p class="text ellipsis5">{{item.description}}</p>
                <p class="date">{{item.type | newsType()}} {{item.publishTime | parseTime()}}</p>
              </dd>
            </dl>
          </router-link>
        </div>
        <div class="paginationBox" v-if="newsList.length">
          <el-pagination
            background
            class="pagination-box"
            @current-change="currentChange"
            :page-size="pageInfo.perPage"
            :total="pageInfo.total"
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { singleNewsSever } from "@/common/api-config";

export default {
  created() {
    this.queryBannerList(); //获取banner
    this.queryList(); //获取APP应用list
  },
  data() {
    return {
      swiperList: [], //banner
      newsList: [],//资讯
      pageInfo:{
        total:99,
        page: 1,
        perPage: 6,
      },
    };
  },
  methods: {
    //获取banner
    queryBannerList() {
      singleNewsSever.queryBannerList({
        type:2,
        num:4,
      }).then(res => {
        if (res.code == 0) {
          this.swiperList = res.data;
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //获取资讯list
    queryList() {
      singleNewsSever.queryNewsList({
        page: this.pageInfo.page,
        perPage: this.pageInfo.perPage,
      })
      .then((res) => {
        if (res.code == 0) {
          this.newsList = res.data.data;
          this.pageInfo.total = res.data.total;
        } else {
          this.newsList = [];
          this.pageInfo.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    //翻页
    currentChange(val) {
      this.pageInfo.page = val;
      this.queryList();
    },
  },
};
</script>

<style scoped lang="scss">
.news-list-box {
  padding-top: 20px;
  padding-bottom: 32px;
  .news-list-content {
    margin: auto;
    width: 1240px;
    padding: 20px;
    background: #fff;
    .news-banner-box {
      .mask-banner-img{
        opacity: 0;
        width: 100%;
        min-height: 400px;
      }
      .swiper-img {
        width: 100%;
        min-height: 400px;
        object-fit: cover;
      }
    }
    .news-content-box{
      padding: 10px 50px;
      .news-list{
        .item{
          display: block;
          padding: 40px 55px;
          border-bottom:1px solid #DFDFDF;
          cursor: pointer;
          .title{
            margin-bottom: 30px;
            font-size: 20px;
            font-weight: bold;
          }
          .dl{
            display: flex;
            .dt{
              margin-right: 22px;
              width: 234px;
              height: 157px;
            }
            .dd{
              flex: 1;
              width: calc(100% - 234px);
              display: flex;
              flex-wrap: wrap;
              align-content: space-between;
              .text{
                width: 100%;
                min-height: 140px;
                line-height: 24px;
                font-size: 16px;
                color: #666;
              }
              .date{
                font-size: 15px;
                color: #999;
              }
            }
          }
        }
      }
      .paginationBox{
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
</style>

